export const mobileMenuModule = async () => {
    const mobileMenu = document.getElementById('mobile-menu');
    const closeButton = document.getElementById('close-mobile-menu');
    const trigger = document.getElementById('mobile-menu-trigger');

    const toggle = function() {
        mobileMenu.classList.toggle('hidden');
    }

    closeButton.addEventListener('click', toggle);
    trigger.addEventListener('click', toggle);
}