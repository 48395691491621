export const menuDropdownModule = async () => {
    const trigger = document.getElementById('menu-dropdown');
    const dropdown = document.getElementById('dropdown');

    trigger.addEventListener('click', function() {
        if(dropdown.classList.contains('lg:hidden')) {
            dropdown.classList.remove('lg:hidden')
            trigger.classList.add('bg-dark')
        } else {
            dropdown.classList.toggle('lg:hidden', true)
            trigger.classList.remove('bg-dark')
        }
    })
}