import Flicking from "@egjs/flicking";

export const flickingModule = () => {
    if (window.innerWidth < 768) {
        const flicking = new Flicking("#flick", {
            renderOnlyVisible: true,
            align: 'prev',
            moveType: 'freeScroll'
        });
    }
};