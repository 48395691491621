export const faqModule = () => {
    const faqItems = document.getElementsByClassName('faq-item');

    for (let i = 0; i < faqItems.length; i++) {
        faqItems[i].addEventListener('click', function () {
            const answer = this.querySelector('.faq-answer');
            const arrow = this.querySelector('.faq-arrow');
            answer.classList.toggle('hidden')
            arrow.classList.toggle('-rotate-180')
        })
    }
}