import {mobileMenuModule} from "./mobile-menu-module";
import {menuDropdownModule} from "./menu-dropdown";
import {faqModule} from "./faq-toggle";
import {flickingModule} from "./flicking";

window.addEventListener('DOMContentLoaded', async function () {
    await menuDropdownModule();
    await mobileMenuModule();
    await faqModule();
    await flickingModule();
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    if(!anchor) {
        return
    }
    anchor.addEventListener('click', function (e) {
        const target =  this.getAttribute('href')
        if ( window.location.pathname !== '/' && target !== '#start' ){
         window.location.href = '/';
        }
        e.preventDefault();
        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

// setTimeout(function() {
//     $('.errors').hide();
// }, 2000)